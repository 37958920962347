/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {

        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages





                // Navigation
                var navMenu     = $('#nav.main__nav');
                var navOpen     = $('#nav__open');
                var navClose    = $('#nav__close');
                var ulMenu    = $('#menu-main');
                ulMenu.attr('aria-haspopup', 'true');
                ulMenu.attr('tabindex', '-1');

                $('body').focusin(function(e) {
                    if ($(navMenu)[0] !== undefined && !$.contains($(navMenu)[0], document.activeElement)) {
                        navClose.trigger('click');
                    }
                });

                // keyboard navigation
                navMenu.on('keydown', function(event){
                    // keyCode -> http://www.javascripter.net/faq/keycodes.htm
                    // 27 = esc
                    if  (event.keyCode === 27) {
                        navClose.trigger('click');
                    }
                });

                // open navigation
                navOpen.click(
                    function(){
                        navOpen.attr('aria-expanded', navOpen.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navClose.attr('aria-expanded', navClose.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        ulMenu.attr('aria-expanded', navOpen.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navMenu.addClass('open');
                        $('html, body').addClass('main__nav-opened');
                    }
                );

                // close navigation
                navClose.click(
                    function(){
                        navOpen.attr('aria-expanded', navOpen.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navClose.attr('aria-expanded', navClose.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        ulMenu.attr('aria-expanded', navOpen.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navMenu.removeClass('open');
                        $('html, body').removeClass('main__nav-opened');
                    }
                );

                // toggle sub-menu
                var menuMainItems = document.querySelectorAll('.main_menu li.menu-item-has-children');

                function setAria(){
                    if ($(window).width() > 820) {

                    }

                    menuMainItems.forEach(function(item) {
                        var firstA = item.querySelector('a');
                        if (firstA.querySelector('.arrowLine') == undefined) {
                            firstA.insertAdjacentHTML('beforeend', '<span class="arrowLine">></span>');
                        }
                    });
                }
                setAria();
                $(window).resize(function() { setAria(); });

                Array.prototype.forEach.call(menuMainItems, function(el, i){
                	el.querySelector('a').addEventListener("click",  function(event){

                        // tolgo class open da tutti gli a
                        menuMainItems.forEach(function(item) {
                            item.classList.remove('open');
                        });

                		if ( this.parentNode.classList.contains('open') ) {
                            this.parentNode.classList.remove('open');
                		} else {
                			this.parentNode.classList.add('open');
                		}
                		event.preventDefault();

                		return false;
                	});

                    // focus
                    el.addEventListener("focusin", function(event){
                        this.classList.add('open');
                    });
                    el.addEventListener("focusout", function(event){
                        this.classList.remove('open');
                    });
                });

                var menuHeaderItems = document.querySelectorAll('.header_menu li.menu-item-has-children');
                var menuSubmenus = document.querySelectorAll('.sub-menu');
                Array.prototype.forEach.call(menuHeaderItems, function(el, i){

                    var toggleMenuFunction = function (event) {
                        event.stopPropagation();

                        // prima chiudo tutti i submenu
                        menuSubmenus.forEach(function(item) {
                            if (item.classList.contains('open')) {
                                item.classList.remove('open');
                            }
                        });

                        // apro il submenu selezionato
                        this.querySelector('.sub-menu').classList.toggle('open');

                        // apro il background bianco se non aperto
                        if (this.closest('div').classList.contains('menuOpen')) {
                            this.closest('div').classList.remove('menuOpen');
                        }
                        this.closest('div').classList.add('menuOpen');
                    };

                    // click su menu principale
                    el.addEventListener("click", toggleMenuFunction, false);

                    // click
                	el.querySelector('a').addEventListener("click",  function(event){
                		event.preventDefault();
                		return false;
                	});
                });

                // chiudo menu principale quando esco da menuOpen
                var menuOpen = document.querySelector('.header__content .content');
                menuOpen.addEventListener('mouseleave', function(event) {
                    console.info(event.target, event.target.classList);

                    if (event.target.classList.contains('menuOpen')) {
                        console.info('chiudo');

                        // prima chiudo tutti i submenu
                        menuSubmenus.forEach(function(item) {
                            if (item.classList.contains('open')) {
                                item.classList.remove('open');
                            }
                        });

                        // poi nascondo il bg bianco
                        var menuOpen = document.querySelector('.menuOpen');
                        if (menuOpen) {
                            menuOpen.classList.remove('menuOpen');
                        }
                    }
                });

                // AOS - animate on scroll
                // setting
                AOS.init({
                    offset: 120,
                    once: true,
                });

                document.querySelectorAll('img')
                    .forEach(function (img) {
                        img.addEventListener('load', function() {
                            AOS.refresh();
                        });
                    });



                // Slider
                var galleryBrandSlider = $('.gallery_brands');

                galleryBrandSlider.slick({
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 2000,
                    infinite: true,
                    pauseOnHover: false,
                    dots: false,
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 1600,
                            settings: {
                                slidesToShow: 6,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 1400,
                            settings: {
                                slidesToShow: 5,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 1000,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 640,
                            settings: {
                                centerMode: true,
                                slidesToShow: 2,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 2.2,
                                slidesToScroll: 1,
                            }
                        },
                    ],
                    rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
                });


                var gallerySliderSingle = $('.gallery--single');

                gallerySliderSingle.slick({
                    arrows: false,
                    autoplay: false,
                    autoplaySpeed: 4000,
                    infinite: true,
                    pauseOnHover: true,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
                });


                // Magnific Popup
                // Image
                var magPopImg = $('.magnificpopup');
                magPopImg.magnificPopup({
                    type: 'image',
                    image: {
                        titleSrc:
                        function(item) {
                            return item.el.find('img').attr('alt');
                        }
                    }
                });

                // Video
                var magPopVideo = $('.magnificpopup--video');
                magPopVideo.magnificPopup({
                    type: 'iframe',
                    iframe: {
                        markup:
                            '<div class="mfp-iframe-scaler">'+
                                '<div class="mfp-close"></div>'+
                                '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                            '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
                        patterns: {
                            youtube: {
                                index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                                id: 'embed/', // String that splits URL in a two parts, second part should be %id%
                                // Or null - full URL will be returned
                                // Or a function that should return %id%, for example:
                                // id: function(url) { return 'parsed id'; }

                                src: '//www.youtube.com/embed/%id%' // URL that will be set as a source for iframe.
                            }
                        },
                    }
                });

                // Gallery
                var magPopGallery = $('.magnificpopup--gallery');
                magPopGallery.each(function() { // the containers for all your galleries

                    $(this).find('a').magnificPopup({
                        //delegate: 'a', // the selector for gallery item
                        type: 'image',
                        gallery: {
                            enabled: true
                        },
                        image: {
                            titleSrc:
                                function(item) {
                                    return item.el.find('img').attr('alt');
                                },
                        },
                    });

                });

                // Gallery
                var wysiwygGallery = $('.text--wysiwyg .gallery');
                wysiwygGallery.each(function() { // the containers for all your galleries

                    $(this).find('a').magnificPopup({
                        //delegate: 'a', // the selector for gallery item
                        type: 'image',
                        gallery: {
                            enabled: true
                        },
                        image: {
                            titleSrc:
                                function(item) {
                                    return item.el.find('img').attr('alt');
                                },
                        },
                    });

                });

                // Arrow to top
                var top_arrow = $('.top_arrow');
                var htmlBody = $('html, body');
                window.addEventListener('scroll', function() {
                    var distanceTop = htmlBody.scrollTop();
                    if (distanceTop > 100) {
                        top_arrow.fadeIn();
                    } else {
                        top_arrow.fadeOut();
                    }
                });

                // aggiunta classe a header fixed quando si scrolla la pagina
                window.addEventListener("scroll", function() {
                    checkSticky();
                });

                function checkSticky() {
                    var header = document.querySelector("header");
                    var stickyTop = window.scrollY;
                    var mainMenu = document.querySelector('#nav');

                    if (stickyTop > 50) {
                        header.classList.add('sticky');
                    } else {
                        if( header.classList.contains('sticky') ){
                            header.classList.remove('sticky');
                        }
                    }
                }

                checkSticky();


            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },



        // Page template Homepage
        'page_template_homepage': {
            init: function() {

                //var path3 = getBBox(document.querySelector("#mask_intro_path5"));
                //console.log(path3);

                // firefox fix
                var userAgentString = navigator.userAgent;
                var firefoxAgent = userAgentString.indexOf("Firefox") > -1;

            },
            finalize: function() {
                // JavaScript to be fired, after page specific JS is fired
                //gsap.registerPlugin(CSSRulePlugin);
            }
        },

    };// Sage

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };// UTIL

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
